<template>
  <div class="files-container">
    <div class="flex-col">
      <div v-for="file in files" :key="file.id" class="flex-row">
        <template v-for="splited in [file.file.split('/')]">
          <b-link
            class="file-name"
            :href="file.file"
            target="_blank"
            :key="`${file.id}-${splited.length}`"
            >{{ splited[splited.length - 1] }}</b-link
          >
        </template>
        <div v-if="allows_crud">
          <b-button
            class="act-button mr-1"
            v-b-tooltip.v-secondary.noninteractive="'Remover Archivo'"
            size="sm"
            @click="deleteFile(file)"
          >
            <b-icon-x-circle></b-icon-x-circle>
          </b-button>
        </div>
      </div>
    </div>
    <div v-if="allows_crud" class="flex-row">
      <div class="d-block w-100 mt-1">
        <b-form-file
          v-model="file"
          :state="!Boolean(file)"
          placeholder="Seleccione o arrastre archivo aquí..."
          drop-placeholder="Arrastre archivo aquí..."
          aria-describedby="input-file-feedback"
          accept="*"
          size="sm"
        ></b-form-file>
        <b-form-invalid-feedback id="input-file-feedback"
          >Asegúrese de subir el Archivo antes de
          Guardar.</b-form-invalid-feedback
        >
      </div>
      <b-button
        :class="Boolean(file) ? 'mb-4' : ''"
        :disabled="!Boolean(file)"
        @click="save"
        class="act-button custom-button ml-1 mb-1 mt-1"
        size="sm"
        >Subir Archivo</b-button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "ActFiles",
  props: {
    Act: {
      type: Object,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      act: this.Act,
      file: null,
      files: [],
    };
  },
  methods: {
    save() {
      const formData = new FormData();
      formData.append("file", this.file);
      this.$restful.Post("/common/file/", formData).then((response) => {
        this.act.files.push(response.id);
        this.files.push(response);
        this.patchAct({ files: this.act.files });
        this.file = null;
      });
    },
    deleteFile(file) {
      let index = this.files.findIndex((x) => x.id == file.id);
      if (index != -1) this.files.splice(index, 1);
      index = this.act.files.findIndex((x) => x == file.id);
      if (index != -1) this.act.files.splice(index, 1);
      this.patchAct({ files: this.act.files });
      this.$restful.Delete(`/common/file/${file.id}/`);
    },
    patchAct(item) {
      this.$restful
        .Patch(`/cideu/act/${this.act.id}/`, item)
        .then((response) => {
          this.$emit("updated", response);
        });
    },
  },
  created() {
    this.act.files.forEach((element) => {
      this.$restful.Get(`/common/file/${element}/`).then((response) => {
        this.files.push(response);
      });
    });
  },
};
</script>

<style scoped>
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.act-button {
  background-color: var(--primary-color);
  color: var(--primary-font-color);
  align-self: center;
}
.custom-button {
  width: 10em;
}
.file-name {
  width: 93%;
}
.files-container {
  margin: 0em 1em;
  border: 2px solid var(--secondary-color);
  background-color: white;
}
</style>